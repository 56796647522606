<template>
  <div class="" v-if="$parent.mockupActive && $parent.mockupActive.layouts">
    <v-row style="max-width: 972px;height: calc(100% - 112px);">
      <v-col cols="12" class="d-flex justify-center">
        <v-btn-toggle>
          <v-btn
            v-for="i in $parent.mockupActive.layouts"
            :key="i.code"
            @click="changeLayout(i)"
            small
            elevation=""
            color=""
            >{{ i.title }}</v-btn
          >
          <!-- <v-btn @click="changeLayout" small elevation="" color="">Back</v-btn> -->
        </v-btn-toggle>
      </v-col>
      <!-- <v-btn @click="test">test</v-btn>
      <v-btn small elevation="" @click="exportImage" color="">exportImage</v-btn> -->
      <v-col cols="12" class="konva__container text-center s-mt12" style="    max-height: 100%;">
        <div id="editor__container" class="editor__container">
          <div ref="konva-wrapper" id="konva-wrapper" class="konva-wrapper" :style="styled(`konva-wrapper`)"></div>
          <div id="editor" :style="styled(`editor`)">
            <v-stage
              ref="stage"
              :config="stageSize"
              @mousedown="handleStageMouseDown"
              @touchstart="handleStageMouseDown"
              @blur="check"
            >
              <v-layer ref="layer">
                <v-image
                  @transformend="handleTransformEnd"
                  :config="{
                    draggable: true,
                    image: image,
                    x: 0,
                    y: 0,
                    scaleX: stageSize.width / $parent.layoutActive.width,
                    scaleY: stageSize.height / $parent.layoutActive.height,
                    width: $parent.layoutActive.width,
                    height: $parent.layoutActive.height,
                    transformsEnabled: 'all',
                    rotation: 0,
                    name: 'image',
                  }"
                />
                <v-transformer
                  ref="transformer"
                  :config="{
                    anchorStroke: 'black',
                    anchorFill: 'white',
                    anchorSize: 8,
                    borderDash: [0, 0],
                    borderStroke: 'white',
                    rotateAnchorOffset: 20,
                    anchorCornerRadius: 10,
                    rotationSnapTolerance: 100,
                    keepRatio: true,
                  }"
                />
              </v-layer>
            </v-stage>
          </div>
          <img
            style="max-width:100%;max-height:100%"
            :src="imageHelpers.url($parent.layoutActive.imageSrc, { width: 0, height: 0 })"
            alt=""
            :style="`transition: all 0.3s ease 0s; background-color: ${colorDefault}`"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const width = window.innerWidth;
const height = window.innerHeight;
import { uploadService } from '@/apis/upload.s';
import axios from 'axios';
import Konva from 'konva';
import { printHubApi } from '@/apis/printHub';

import imageHelpers from '@/helpers/image';
export default {
  props: {
    colorDefault: {
      type: String,
      default: `rgb(255, 255, 255)`,
    },
    dataCampaign: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      imageHelpers,

      selectedShapeName: '',
      configRect: {
        x: 0,
        y: 0,
        width: 100,
        name: 'rect',
        height: 50,
        fill: 'red',
        stroke: 'black',
        strokeWidth: 1,
        draggable: true,
      },
      stageSize: {
        width: 327,
        height: 373,
      },
      scale: {
        X: 0.5,
        Y: 0.5,
      },
      isDragging: false,
      image: null,
      design: null,
      z: null,
      imgE: null,
    };
  },
  watch: {
    '$store.state.printHub.mockupSelected': function() {},
    layoutActive: {
      handler: function() {
        let { top, left, width, height } = this.layoutActive;
      },
      deep: true,
    },
    dataCampaign: {
      handler() {
        // return this.setDataLayout();
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    console.log('dataCampaign', this.dataCampaign);
    this.$nextTick(function() {
      let box = document.querySelector('#editor');
      const observer = new ResizeObserver(() => {
        this.stageSize.width = box.offsetWidth;
        this.stageSize.height = box.offsetHeight;
      });
      observer.observe(box);
    });
  },
  methods: {
    styled(element) {
      console.log('this.$parent.layoutActive', this.$parent.layoutActive);
      let { top, left, width, height } = this.$parent.layoutActive.slots[0];
      console.log(`top, left, width, height`, element);
      if (element === 'editor') {
        return `top:${top}%;left: ${left}%; width: ${width}%; height: ${height}%;`;
      }

      if (element === `konva-wrapper`) {
        return `width: calc(${width}% + 0px); height: calc(${height}% + 2px); top: calc(${top}% - 1px); left: calc(${left}% - 1px);`;
      }
    },
    loadImage(slot) {
      this.$parent.layoutActive = this.$parent.mockupActive.layouts.find(it => it.code === slot);
      const image = new window.Image();
      // image.crossOrigin = 'Anonymous';
      image.src = this.imageHelpers.url(this.$parent.dataCampaign.designs[slot === 'front' ? 0 : 1].imageSrc, {
        width: 500,
        height: 500,
      });
      image.onload = () => {
        this.image = image;
        this.$forceUpdate();
      };
    },
    changeLayout(i) {
      this.$parent.layoutActive = i;
      this.$parent.designActive = this.$parent.dataCampaign.designs.find(it => i.code === it.slot);
      const image = new window.Image();
      image.src = this.imageHelpers.url(
        this.$parent.dataCampaign.designs[this.$parent.designActive.slot === 'front' ? 0 : 1].imageSrc !== ''
          ? this.$parent.dataCampaign.designs[this.$parent.designActive.slot === 'front' ? 0 : 1].imageSrc
          : null,
        { width: 500, height: 500 },
      );

      image.onload = () => {
        this.image = image;
        this.$forceUpdate();
      };
    },
    noClick() {
      // here we need to manually attach or detach Transformer node
      const transformerNode = this.$refs.transformer.getNode();
      const stage = transformerNode.getStage();
      transformerNode.nodes([]);
    },
    setData(attrs, e) {
      this.$parent.designActive.rotate = attrs.rotation;
      this.$parent.designActive.width = (attrs.scaleX / (this.stageSize.width / this.$parent.layoutActive.width)) * 100;
      this.$parent.designActive.height =
        (attrs.scaleY / (this.stageSize.height / this.$parent.layoutActive.height)) * 100;
      this.$parent.designActive.top = 100 * (e.target.y() / this.stageSize.height); //y
      this.$parent.designActive.left = 100 * (e.target.x() / this.stageSize.width); //x
      console.log(`this.$parent.designActive`, this.$parent.designActive);
    },
    handleTransformEnd(e) {
      console.log(e, e.target.x(), e.target.y(), 'handleTransformEnd');

      let attrs = e.target.attrs;
      this.setData(attrs, e);
      console.log(this.$parent.designActive, ' this.$parent.designActive');
    },
    handleStageMouseDown(e) {
      console.log(e.target, e.target.x(), e.target.y(), 'handleStageMouseDown');
      let attrs = e.target.attrs;
      this.setData(attrs, e);
      // clicked on stage - clear selection
      if (e.target === e.target.getStage()) {
        this.selectedShapeName = '';
        this.updateTransformer();
        return;
      }

      // clicked on transformer - do nothing
      const clickedOnTransformer = e.target.getParent().className === 'Transformer';
      if (clickedOnTransformer) {
        return;
      }

      // find clicked rect by its name
      const name = e.target.name();
      const rect = {};
      if (rect) {
        this.selectedShapeName = name;
      } else {
        this.selectedShapeName = '';
      }
      this.updateTransformer();
    },
    check(e) {
      console.log(e);
    },
    updateTransformer(e) {
      // here we need to manually attach or detach Transformer node
      const transformerNode = this.$refs.transformer.getNode();
      const stage = transformerNode.getStage();
      const { selectedShapeName } = this;

      const selectedNode = stage.findOne('.' + selectedShapeName);
      // do nothing if selected node is already attached
      if (selectedNode === transformerNode.node()) {
        return;
      }

      if (selectedNode) {
        // attach to another node
        transformerNode.nodes([selectedNode]);
      } else {
        // remove transformer
        console.log(`remove`);
        transformerNode.nodes([]);
      }
    },
    handlerFunction(html) {},
    objectId() {
      return this.hex(Date.now() / 1000) + ' '.repeat(16).replace(/./g, () => this.hex(Math.random() * 16));
    },
    hex(value) {
      return Math.floor(value).toString(16);
    },
    stageClick(e) {
      console.log(e, this.$refs.transformer);
      let stage = this.$refs.stage.getStage();
      // if click on empty area - remove all transformers
      if (e.target === stage) {
        // stage.find('Transformer').destroy();
        stage.draw();
        return;
      }
    },
    dleDragStart() {
      this.isDragging = true;
    },
    handleDragEnd() {
      this.isDragging = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.editor__container {
  max-height: 766px;
  height: calc(100% - 112px);
  display: inline-block;
  position: relative;
}
.konva-wrapper {
  // width: calc(53.4382% + 2px);
  // height: calc(48.8578% + 2px);
  // top: calc(15.6503% - 1px);
  // left: calc(22.3348% - 1px);
  position: absolute;
  border: 1px dashed rgba(26, 31, 35, 0.235);
}
#editor {
  // top: 15.6503%;
  // left: 22.3348%;
  // width: 53.4382%;
  // height: 48.8578%;
  position: absolute;
  div:first-of-type {
    width: 100%;
    height: 100%;
  }
}
</style>
