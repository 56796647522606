<template>
  <div class="" style="margin-top:100px">
    <v-row v-if="!loading" style="max-width: 972px;height: calc(100% - 112px);">
      <div style="width:700px">
        <img v-if="preview" style="max-width:100%" :src="preview" alt="" srcset="" />
      </div>
    </v-row>

    <div style="width: 100%; height: 100%" v-else class="d-flex justify-center align-center">
      <page-loader />
    </div>
  </div>
</template>

<script>
import { printHubApi } from '@/apis/printHub';

export default {
  props: ['colorDefault'],
  data() {
    return {
      preview: '',
      loading: false,
    };
  },
  methods: {
    async getPreview(option) {
      this.loading = true;
      try {
        console.log('this.$parent.dataCampaign', this.$parent.dataCampaign.designs[0].imageSrc);
        let mockup = JSON.parse(JSON.stringify(this.$store.state.printHub.mockupSelected));
        if (this.$parent.dataCampaign.designs[0].imageSrc) {
          let convertData = {
            color: this.colorDefault,
            ...this.$parent.dataCampaign.designs[0],
          };
          convertData.layout = this.$parent.dataCampaign.designs[0].slot;
          delete convertData.slot;
          let res = await printHubApi.previewMockup(mockup._id, convertData);
          this.preview = res.data.src;
          console.log(`res`, res);
        }
      } catch (error) {
        console.log('error', error);
      }
      this.loading = false;
    },
  },
  mounted() {
    this.getPreview();
  },
};
</script>

<style lang="scss" scoped></style>
