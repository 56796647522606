<template>
  <div class="py-3 list-pb-selected pa-2" style="width: 90px !important;">
    <ul class="list-inline m-0 base-products__container s-pb16 drag">
      <li class="s-px16 s-pt16 mt-2 pa-2" v-for="i in mockupSelected" :key="i.title" @click="mockupActive(i)">
        <div class="s-tooltip-fixed base-product-3437 pos-rlt">
          <!-- <span style="display:none">{{ i.title }}</span> -->
          <a class="img-thumbnail active"
            ><img style="width: 100%;" :src="imageHelpers.url(i.imageSrc, { width: 0, height: 0 })"
          /></a>
        </div>
      </li>

      <!-- <li class="s-px16 s-pt16 mt-2 pa-3" :key="i">
        <div class="add-catalog s-tooltip-fixed base-product-3437 pos-rlt">
          +
        </div>
      </li> -->
    </ul>
  </div>
</template>

<script>
import imageHelpers from '@/helpers/image';

export default {
  props: ['mockupSelected'],
  data() {
    return {
      imageHelpers,
    };
  },
  mounted() {},
  methods: {
    mockupActive(i) {
      this.$store.commit(`setMockupSelected`, i);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-pb-selected {
  .base-products__container {
    list-style-type: none;
    li {
      border: 1px solid #dddd;
    }
    .add-catalog {
      font-size: 30px;
      padding: px;
      /* padding: 15px; */
      display: flex;
      justify-content: center;
    }
  }
}
</style>
<style>
.v-application ol,
.v-application ul {
  padding-left: 0px !important;
}
</style>
