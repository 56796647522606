var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$parent.mockupActive && _vm.$parent.mockupActive.layouts)?_c('div',{},[_c('v-row',{staticStyle:{"max-width":"972px","height":"calc(100% - 112px)"}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-btn-toggle',_vm._l((_vm.$parent.mockupActive.layouts),function(i){return _c('v-btn',{key:i.code,attrs:{"small":"","elevation":"","color":""},on:{"click":function($event){return _vm.changeLayout(i)}}},[_vm._v(_vm._s(i.title))])}),1)],1),_c('v-col',{staticClass:"konva__container text-center s-mt12",staticStyle:{"max-height":"100%"},attrs:{"cols":"12"}},[_c('div',{staticClass:"editor__container",attrs:{"id":"editor__container"}},[_c('div',{ref:"konva-wrapper",staticClass:"konva-wrapper",style:(_vm.styled("konva-wrapper")),attrs:{"id":"konva-wrapper"}}),_c('div',{style:(_vm.styled("editor")),attrs:{"id":"editor"}},[_c('v-stage',{ref:"stage",attrs:{"config":_vm.stageSize},on:{"mousedown":_vm.handleStageMouseDown,"touchstart":_vm.handleStageMouseDown,"blur":_vm.check}},[_c('v-layer',{ref:"layer"},[_c('v-image',{attrs:{"config":{
                  draggable: true,
                  image: _vm.image,
                  x: 0,
                  y: 0,
                  scaleX: _vm.stageSize.width / _vm.$parent.layoutActive.width,
                  scaleY: _vm.stageSize.height / _vm.$parent.layoutActive.height,
                  width: _vm.$parent.layoutActive.width,
                  height: _vm.$parent.layoutActive.height,
                  transformsEnabled: 'all',
                  rotation: 0,
                  name: 'image',
                }},on:{"transformend":_vm.handleTransformEnd}}),_c('v-transformer',{ref:"transformer",attrs:{"config":{
                  anchorStroke: 'black',
                  anchorFill: 'white',
                  anchorSize: 8,
                  borderDash: [0, 0],
                  borderStroke: 'white',
                  rotateAnchorOffset: 20,
                  anchorCornerRadius: 10,
                  rotationSnapTolerance: 100,
                  keepRatio: true,
                }}})],1)],1)],1),_c('img',{staticStyle:{"max-width":"100%","max-height":"100%"},style:(("transition: all 0.3s ease 0s; background-color: " + _vm.colorDefault)),attrs:{"src":_vm.imageHelpers.url(_vm.$parent.layoutActive.imageSrc, { width: 0, height: 0 }),"alt":""}})])])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }