<template>
  <v-row v-if="mockup">
    <v-col cols="12" class="">
      <div class="pa-2">
        <h2>
          {{ mockup.title }}
          <v-btn small @click="$emit('update:showDialog', true)" color="">Select mockup </v-btn>
        </h2>
        <div>
          <!-- Dimension: 2100 x 2400px -->
        </div>
      </div>
    </v-col>
    <v-col cols="12">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12">
      <div class="pa-2">
        <div class="d-flex align-center">
          <h3 class="mt-5 mr-3">Color</h3>
          <v-checkbox v-model="allColor" @click="selectAllColor" hide-details="" label="Select All"> </v-checkbox>
        </div>
        <div>
          <div
            role="tabpanel"
            aria-labelledby="s-collapse-head-4823"
            id="s-collapse-content-4823"
            class="s-collapse-item__wrap"
          >
            <div class="s-collapse-item__content">
              <div style="display: flex;flex-wrap: wrap;" class="color-section">
                <div
                  v-for="(i, k) in mockup.colorOption.values"
                  :key="i.code"
                  @click="removeColor(i.code, k)"
                  @mouseover="changeColor(i.code)"
                  class="s-tooltip-fixed item-color mt-3"
                  animated=""
                >
                  <div class="color cursor-pointer" :style="`background-color: ${i.code}`">
                    <div class="color__active">
                      <span class="s-icon h-100p is-small" style="color: rgb(255, 255, 255);"
                        ><i class="mdi mdi-check mdi-18px"></i
                      ></span>
                    </div>
                  </div>
                </div>
                <div class="s-tooltip-fixed item-color mt-3" animated="">
                  <div class="color cursor-pointer" :style="``">
                    <div class="color__active">
                      <span class="s-icon h-100p is-small" style=""><i class="fas fa-plus mt-1"></i></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-col>
    <v-col cols="12">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12">
      <div class="pa-2">
        <div class="d-flex align-center">
          <h3 class="mt-5 mr-3">Size</h3>
          <!-- <v-checkbox hide-details="" label="Select All"> </v-checkbox> -->
        </div>
        <div>
          <div class="size-section " style="display: flex;flex-wrap: wrap;">
            <v-btn :key="i.code" v-for="i in mockup.sizeOption.values" small elevation="" class="mx-2 mt-2" outlined>{{
              i.code
            }}</v-btn>
          </div>
        </div>
      </div>
    </v-col>
    <v-col cols="12">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12">
      <div class="pa-2">
        <div class="d-flex">
          <h4>Front side</h4>
          <v-spacer />
          <span class="pointer" @click="selectImage(`imageFront`)">Add Image</span>
        </div>
        <div>
          <div v-if="imageFront" class="d-flex pa-2 item-artwork">
            <div>
              <v-avatar tile size="50" class="mr-1">
                <img :src="imageHelpers.url(imageFront.imageSrc)" alt="" srcset="" />
              </v-avatar>
              <span class="d-flex" style="max-width:180px">{{ imageFront.name }}</span>
            </div>

            <span>
              <v-icon @click="imageFront = null" color="grey" size="12">
                fas fa-trash
              </v-icon></span
            >
          </div>
        </div>
      </div>
    </v-col>
    <v-col cols="12">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12" v-if="dataCampaign.designs[1]">
      <div class="pa-2">
        <div class="d-flex">
          <h4>Back side</h4>
          <v-spacer />
          <span class="pointer" @click="selectImage(`imageBack`)">Add Image</span>
        </div>

        <div>
          <div v-if="imageBack" class="d-flex pa-2 item-artwork">
            <div>
              <v-avatar tile size="50" class="mr-1">
                <img :src="imageHelpers.url(imageBack.imageSrc)" alt="" srcset="" />
              </v-avatar>
              <span class="d-flex" style="max-width:180px">{{ imageBack.name }}</span>
            </div>

            <span>
              <v-icon @click="imageBack = null" color="grey" size="12">
                fas fa-trash
              </v-icon></span
            >
          </div>
        </div>
      </div>
    </v-col>
    <v-dialog persistent v-model="showArtwork" width="1100">
      <v-card>
        <v-card-title>Select artwork</v-card-title>
        <div class="close-icon" @click="showArtwork = false"><v-icon>fas fa-times</v-icon></div>
        <v-card-text>
          <art-work :dialog="true" @selectArtwork="selectArtwork" />
        </v-card-text>
        <v-card-actions>
          <!-- <v-spacer></v-spacer>
          <v-btn @click="showModelAvailable = false">Cancel</v-btn>
          <v-btn color="primary" @click="onUpdateBulk"
            >Make products {{ action === 'make-available' ? 'available ' : 'unavailable' }}</v-btn
          > -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import listColoFake from '@/const/color.json';
import { printHubApi } from '@/apis/printHub';
import ArtWork from '../../../apps/print-on-demand/library/ArtWork.vue';
import imageHelpers from '@/helpers/image';

export default {
  props: {
    showDialog: {
      type: Boolean,
      deafault: false,
    },
    colorDefault: {
      type: String,
      default: `rgb(255, 255, 255)`,
    },
    dataCampaign: {
      type: Object,
      default: () => {},
    },
  },
  components: { ArtWork },
  watch: {
    '$store.state.printHub.mockupSelected': function() {
      // this.mockupActive = this.$store.state.printHub.mockupSelected;
      // this.layoutActive = this.mockupActive.layouts[0];
      // return this.setDataLayout();
    },
  },
  data() {
    //src="https://pbimg.xpdcdn.com/pbase/shop-id/10250304/artworks/0x400@ec91a5eedcd264ca48462bbc1a9c41a2.png"

    return {
      mockupActive: null,
      layoutActive: null,
      showArtwork: false,
      listColoFake: listColoFake,
      imageFront: null,
      imageBack: null,
      artwork: [],
      layout: 'imageFront',
      imageHelpers,
      mockup: null,
      listColor: [
        `rgb(255, 255, 255)`,
        `rgb(8, 8, 8);`,
        `rgb(74, 18, 20)`,
        `rgb(6, 124, 74)`,
        `rgb(135, 137, 149)`,
        `rgb(126, 125, 125)`,
        `rgb(28, 32, 61)`,
        `rgb(43, 99, 186)`,
        `rgb(143, 173, 227)`,
        `rgb(253, 195, 217)`,
        `rgb(255, 111, 173)`,
        `rgb(229, 10, 40)`,
        `rgb(79, 35, 122)`,
        `rgb(138, 161, 64)`,
        `rgb(60, 169, 88)`,
        `rgb(21, 56, 14);`,
        `rgb(239, 200, 11)`,
        `rgb(227, 78, 48)`,
        `rgb(53, 35, 31)`,
      ],
      allColor: true,
    };
  },
  async created() {
    // this.mockupActive = this.$store.state.printHub.mockupSelected;
    // this.layoutActive = this.mockupActive.layouts[0];
    // this.setDataLayout();
    let res = await printHubApi.getArtwork({ page: this.page - 1, limit: this.limit });
    this.artwork = res.data.artwork;
  },
  mounted() {
    this.mockup = JSON.parse(JSON.stringify(this.$store.state.printHub.mockupSelected));
    // console.log(`sdasd`, this.$store.state.printHub.mockupSelected);
  },

  methods: {
    selectAllColor() {
      this.mockup = JSON.parse(JSON.stringify(this.$store.state.printHub.mockupSelected));
      this.$emit('mockup', this.mockup);
    },
    removeColor(i, k) {
      this.allColor = false;
      this.mockup.colorOption.values.splice(k, 1);
      this.$emit('mockup', this.mockup);
    },
    selectArtwork(item) {
      // this.$emit(`selectArtwork`, { item, layout: this.layout });
      this[this.layout] = item;
      if (this.layout === 'imageFront') {
        this.dataCampaign.designs[0].imageSrc = item.imageSrc;
        this.dataCampaign.title = item.name.split(`.`)[0];
        console.log(item, 'sdsd');
        this.$emit(`selectArtwork`, `front`);
      }
      if (this.layout === 'imageBack') {
        this.dataCampaign.designs[1].imageSrc = item.imageSrc;
        this.$emit(`selectArtwork`, `back`);
      }
      this.showArtwork = false;
    },
    selectImage(layout) {
      this.showArtwork = true;
      this.layout = layout;
    },
    changeColor(i) {
      console.log('i', i);
      this.$emit(`changeColor`, i);
    },
  },
};
</script>

<style lang="scss" scoped>
.item-artwork {
  background: #f5f6f7;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
}
.editor-side-bar .color-size-select-section .size-section button.active {
  background: #f5f6f7;
  border-color: #b5bdc2;
}
.editor-side-bar .color-size-select-section .size-section button {
  margin-left: 0;
  min-width: 64px;
}
.s-button.is-small,
.s-button.is-small span {
  font-size: 0.75rem;
}
.s-button.is-small {
  border-radius: 7px 7px 7px 7px;
  height: 32px;
  letter-spacing: 0;
  line-height: 16px;
}
.s-button {
  background-color: #fff;
  border-color: #dbdfe1;
  border-width: 1px;
  color: #363f44;
  cursor: pointer;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: calc(0.375em - 1px) 0.75em calc(0.375em - 1px) 0.75em;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  height: 40px;
}
.item-color {
  cursor: pointer;
  .color {
    margin-top: px;
    position: relative;
    content: '';
    width: 32px;
    height: 32px;
    background-color: hsla(0, 0%, 100%, 0);
    border-radius: 4px;
    border: 1px solid #dbdfe1;
    -webkit-box-shadow: 0 0 1px grey;
    box-shadow: 0 0 1px grey;
  }
}
.editor-side-bar .color-size-select-section .color-section .color {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin: 0 8px 8px 0;
  border: none;
  outline: none;
  -webkit-box-shadow: 0 0 2px #ccc;
  box-shadow: 0 0 2px #ccc;
  -webkit-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
  padding: 0;
}
.s-icon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
}
.s-tooltip-fixed {
  width: 32px;
  height: 32px;
  margin-right: 5px;
  border-radius: 6px;
  text-align: center;
}
</style>
