<template>
  <div>
    <div v-if="!isLoading && mockupSelected.length > 0" style="height: calc(100% - 60px)">
      <v-app-bar app flat height="60" style="right: 0px; left: 0px; background-color: white" class="elevation-1">
        <div class="d-flex pointer align-center" style="width: 100%; justify-content: space-between">
          <div class="d-flex pointer align-center" style="width: 350px" @click="back">
            <i class="fas fa-arrow-left fz-20 pt-1 mr-2"></i>
            <h3>Back</h3>
          </div>
          <div>
            <v-btn-toggle>
              <v-btn elevation="" color="" @click="typeButton = 'edit'">Edit</v-btn>
              <v-btn elevation="" color="" @click="typeButton = 'preview'">Preview</v-btn>
            </v-btn-toggle>
          </div>
          <div>
            <v-btn :loading="isLoading" @click="submit" color="primary"> Save </v-btn>
          </div>
        </div>
        <!-- <span v-if="itemDefault">
        {{ itemDefault.targetCharacter.code }}
      </span> -->
        <!-- <v-spacer></v-spacer> -->
      </v-app-bar>

      <v-navigation-drawer clipped left app width="450">
        <div class="d-flex" style="height: 100%">
          <div style="width: 90px; border-right: 1px solid #ddd">
            <catalog-selected :mockupSelected="mockupSelected" />
          </div>
          <div>
            <option-selected
              @mockup="getMockupSelect"
              @selectArtwork="selectArtwork"
              :dataCampaign="dataCampaign"
              @changeColor="changeColor"
              :showDialog.sync="showDialog"
            />
          </div>
        </div>
      </v-navigation-drawer>

      <div style="background-color: #f2f2f8; height: 100%" class="d-flex justify-center">
        <editor-mockup
          v-show="typeButton === `edit`"
          ref="editor"
          :dataCampaign="dataCampaign"
          :colorDefault="colorDefault"
        />
        <preview :colorDefault="colorDefault" v-if="typeButton === `preview`" />
      </div>

      <v-overlay :value="overlay">
        <div class="fz-18">
          Loading ....
          <v-progress-circular :size="30" color="primary" indeterminate></v-progress-circular>
        </div>
      </v-overlay>
      <template-selected :templates="templates" :content="' collection?'" title="Select Mockup" />
    </div>
    <div v-else></div>
  </div>
</template>

<script>
import imageHelpers from '@/helpers/image';

import { bookApi } from '@/apis/book';

import event from '@/plugins/event-bus';
import CatalogSelected from './components/CatalogSelected.vue';
import OptionSelected from './components/OptionSelected.vue';
import EditorMockup from './components/EditorMockup.vue';
import { printHubApi } from '@/apis/printHub';
import listColoFake from '@/const/color.json';
import { log } from 'handlebars';
import Preview from './components/Preview.vue';
import TemplateSelected from './components/TemplateSelected.vue';

export default {
  components: { CatalogSelected, OptionSelected, EditorMockup, Preview, TemplateSelected },
  data() {
    return {
      showDialog: false,
      typeButton: 'edit',
      mockupActive: null,
      layoutActive: null,
      designActive: null,
      overlay: false,
      pageLayout: {},
      imageHelpers,
      isLoading: false,
      isLoadingImage: true,
      templates: [],
      sideRight: true,
      type: null,
      objects: [],
      selectedItem: 0,
      itemDefault: null,
      bookDetail: null,
      xmlContent: '',
      executor: null,
      colorDefault: null,
      mockupSelected: [],
      dataCampaign: {
        mockup: '',
        title: 'T-shirt Product',
        description: '',
        price: 0,
        tags: [],
        color: [], // Màu
        // attributes: {
        //   sku: 'SKU ',
        //   barCode: 'Barcode ',
        //   cost: 0,
        //   salePrice: 19.95,
        //   compareAtPrice: 40,
        //   inventoryPolicy: 'deny',
        //   requiresShipping: true,
        //   weightUnit: 'lb',
        //   weight: 0,
        //   inventoryQuantity: null,
        // },
        variants: [],
        options: [
          {
            name: 'Color',
            values: [],
          },
          {
            name: 'Size',
            values: [],
          },
        ],
        designs: [],
        product: null, // Mã sản phẩm nếu đã được tạo
      },
    };
  },
  watch: {
    '$store.state.printHub.mockupSelected': function() {
      console.log('this.$store.state.printHub.mockupSelected', this.$store.state.printHub.mockupSelected);
      this.mockupActive = this.$store.state.printHub.mockupSelected;
      console.log('this.mockupActive.layouts', this.mockupActive.layouts[0]);
      this.layoutActive = this.mockupActive.layouts[0];
      return this.setDataLayout();
    },
  },
  // components: { BookEditor },
  computed: {},
  methods: {
    getMockupSelect(m) {
      this.dataCampaign.mockup = m._id;
      this.dataCampaign.options[0].values = JSON.parse(JSON.stringify(m.colorOption.values));
      this.dataCampaign.options[1].values = JSON.parse(JSON.stringify(m.sizeOption.values));
    },
    selectArtwork(i) {
      this.$refs.editor.loadImage(i);
    },
    setDataLayout() {
      if (this.mockupActive && this.mockupActive.layouts) {
        this.dataCampaign.designs = this.mockupActive?.layouts?.map((item, k) => {
          return {
            slot: item.code, // Mã layout gắn design
            imageSrc: ``,
            left: 0,
            top: 0,
            width: 100,
            height: 100,
            rotate: 0,
          };
        });
        this.designActive = this.dataCampaign.designs[0];
      }
    },
    async submit() {
      try {
        if (this.dataCampaign.designs.length > 0 && this.dataCampaign.designs[0].imageSrc) {
          this.isLoading = true;
          this.dataCampaign.mockup = localStorage.getItem(`catalog`);
          this.dataCampaign.variants = [];
          this.dataCampaign.productType = this.mockupSelected[0].title;
          this.dataCampaign.designs = this.dataCampaign.designs.filter(it => it.imageSrc && it.imageSrc !== '');
          let res = await printHubApi.createCampaign({
            campaign: {
              ...this.dataCampaign,
              templates: this.templates.filter(item => item.isRendered).map(item => item._id),
            },
            launch: 0,
          });
          window.location.href = `/admin/app/catalog-product/campaigns/${res.data.campaign._id}`;
        } else {
          alert(`Upload Design`);
        }

        this.isLoading = false;
      } catch (error) {
        console.log(`error`, error);
      }
    },
    back() {
      window.location.href = `/admin/app/catalog-product/campaigns/`;
    },
    changeColor(i) {
      // var evt = new CustomEvent('MyEventType', { detail: 'Any Object Here' });
      // window.dispatchEvent(evt);
      this.colorDefault = i;
    },
    change() {},
    async getTemplateByIdMockup() {
      try {
        let res = await printHubApi.getTemplateByIdMockup(localStorage.getItem(`catalog`));
        this.templates = res.data.templates;
        this.dataCampaign.layouts = res.data.templates.filter(item => item.isDefault);
        this.dataCampaign.frames = res.data.templates.filter(item => !item.isDefault);

        console.log(`getTemplateByIdMockup`, this.dataCampaign);
      } catch (error) {
        console.log('error', error);
      }
    },
    async getMockup(id) {
      try {
        let res = await printHubApi.getMockupById(localStorage.getItem(`catalog`));
        this.mockupSelected = [res.data.mockup] || [];
        this.mockupSelected[0].layouts = this.dataCampaign.layouts;
        this.mockupSelected[0].frames = this.dataCampaign.frames;
        this.$store.commit(`setMockupSelected`, this.mockupSelected[0]);

        this.dataCampaign.options[0].values = res.data.mockup.colorOption.values; //hard code
        this.colorDefault = this.dataCampaign.options[0].values[0].code || null;
        this.dataCampaign.options[1].values = res.data.mockup.sizeOption.values;
      } catch (error) {
        console.log(error, 'error');
      }
    },
  },
  async created() {
    let tab = '';
    this.isLoading = true;
    await this.getTemplateByIdMockup();
    await this.getMockup();
    this.isLoading = false;
    // let res = await printHubApi.get();
  },
  mounted() {
    // window.addEventListener(
    //   'MyEventType',
    //   function(evt) {
    //     console.log(`evt`);
    //   },
    //   false,
    // );
  },
};
</script>

<style lang="scss"></style>
