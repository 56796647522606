<template>
  <v-dialog persistent v-model="$parent.showDialog" width="700" style="transform-origin: center top">
    <v-card relative>
      <!-- Title -->
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>

      <!-- Close icon -->
      <div class="close-icon" @click="$parent.showDialog = false">
        <v-icon>fas fa-times</v-icon>
      </div>
      <v-divider class="mb-3"></v-divider>
      <!-- Content -->
      <v-card-text class="">
        <div class="content-product">
          <v-row gutters>
            <draggable v-model="$parent.templates" class="d-flex" style="flex-wrap: wrap">
              <div v-for="(i, k) in templates" :key="`a${k}`" class="pa-1 tab-image--ali">
                <v-checkbox color="#4161cb" class="checkbox-c" hide-details="" v-model="i.isRendered" />
                <img
                  style="max-width: 200px;min-height:200px"
                  :src="imageHelpers.url(i.imageSrc, { width: 1000, height: 0 })"
                  alt=""
                  srcset=""
                />
              </div>
            </draggable>
          </v-row>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <!-- Footer -->
      <v-card-actions v-if="showFooter">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="$parent.showDialog = false"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import imageHelpers from '@/helpers/image';
import draggable from 'vuedraggable';

export default {
  components: { draggable },

  props: {
    templates: {
      type: Array,
      default: () => [],
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    content: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    titleButton: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      imageHelpers,
    };
  },
};
</script>

<style lang="scss" scoped>
.name-delete {
  font-size: 18px;
  font-weight: bold;
}
.tab-image--ali {
  position: relative;
  img {
    position: relative;
    outline: 1px solid black;
  }
  .checkbox-c {
    position: absolute;
    top: -10px;
    left: 10px;
    z-index: 10;
    width: 24px;
    height: 24px;
    .v-input__control {
      margin-top: -20px;
    }
  }
}
</style>
